import React from 'react'
import { View } from 'react-native'
import COLORS from '../../utils/colors'

export const UncheckedIcon = (props) => (
    <View
        {...props}
        style={[
            {
                width: 20,
                height: 20,
                borderRadius: 5,
                borderWidth: 1,
                borderColor: COLORS.COLOR_DISABLE_GREY,
            },
        ]}
    />
)
