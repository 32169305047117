import React from 'react'
import { CheckBox } from '@rneui/base'
import { StyleProp, View } from 'react-native'
import { CheckedIcon } from './CheckedIcon'
import { UncheckedIcon } from './UnCheckedIcon'

export type NestedCheckboxProps = {
    id: string
    title: string
    checked?: boolean
    handleNestedCheck: (id: string) => void
    customCheckedIcon?: React.ReactNode
    customUncheckedIcon?: React.ReactNode
    style?: StyleProp<any>
    testID?: string
}

export function NestedCheckbox({
    id,
    title,
    checked,
    handleNestedCheck,
    customCheckedIcon,
    customUncheckedIcon,
    style,
    testID,
}: NestedCheckboxProps) {
    return (
        <CheckBox
            title={title}
            checkedIcon={
                customCheckedIcon ? (
                    <View>{customCheckedIcon}</View>
                ) : (
                    <CheckedIcon />
                )
            }
            uncheckedIcon={
                customUncheckedIcon ? (
                    <View>{customUncheckedIcon}</View>
                ) : (
                    <UncheckedIcon />
                )
            }
            textStyle={{ fontFamily: 'Arial' }}
            checked={checked}
            containerStyle={[{ backgroundColor: 'transparent' }, style]}
            onPress={() => handleNestedCheck(id)}
            testID={testID}
            size={20}
        />
    )
}
