import * as React from 'react'
import { Svg, Rect, Path } from 'react-native-svg'

export function IndeterminateIcon(props) {
    return (
        <Svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <Rect width={20} height={20} rx={6} fill="#6200EA" />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5 10a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1z"
                fill="#fff"
            />
        </Svg>
    )
}
